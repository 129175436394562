<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveMotivoDebaja()"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="motivo"
                label="Motivo"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(motivo),
                    rules.maxLength(motivo, 100)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-select
                item-text="value"
                item-value="id"
                v-model="tipoDeMotivo"
                :items="motivosList"
                label="Tipo de movimiento"
                :rules="rules.required"
                @change="tipoDeMotivo == 6 ? descontable = false : ''"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="3">
              <template>
                <v-container class="pb-5 pt-0 d-flex align-items-center" fluid>
                  <v-switch
                    v-model="descontable"
                    id="descontable"
                    class="ml-2"
                    :disabled = "tipoDeMotivo == 6"
                  ></v-switch>
                  <label class="pt-2 pb-0" for="descontable">Descontable</label>
                </v-container>
              </template>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditMotivosDeBaja",
  props: {
    motivosDeBajaObject: {
      type: Object,
      default: null
    },
    configMotivosDeBaja: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_MOTIVO_DE_BAJA,
    rules: rules,
    motivo: null,
    motivosList: [],
    descontable: null,
    tipoDeMotivo: null
  }),
  created() {
    this.loadMotivosList();
    if (this.motivosDeBajaObject) {
      this.setMotivoDeBaja();
    } else {
      this.newMotivoDeBaja();
    }
  },

  methods: {
    ...mapActions({
      postMotivosDeBaja: "configAfiliaciones/postMotivosDeBaja",
      getTiposMovBaja: "configAfiliaciones/getTiposMovBaja",
      setAlert: "user/setAlert"
    }),
    async newMotivoDeBaja() {
      this.formEditTitle = enums.titles.NUEVO_MOTIVO_DE_BAJA;
    },

    async setMotivoDeBaja() {
      this.motivoId = this.motivosDeBajaObject.tipoMovMotivoId;
      this.motivo = this.motivosDeBajaObject.tipoMovMotivoNombre;
      this.descontable = this.motivosDeBajaObject.tmDescontable;
      this.tipoDeMotivo = this.motivosDeBajaObject.tmId;
    },

    async loadMotivosList() {
      this.motivosList = await this.getTiposMovBaja();
    },
    async saveMotivoDebaja() {
      let data = {
        tipoMovMotivoNombre: this.motivo,
        tmDescontable: this.descontable,
        tmId: this.tipoDeMotivo,
        tipoMovMotivoId: this.motivosDeBajaObject?.tipoMovMotivoId
      };
      const res = await this.postMotivosDeBaja(data);
      if (res.status === 200) {
        setTimeout(() => {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
        }, 1200);
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
